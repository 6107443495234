export default [
    {
      title: 'breadcrumb.business',
      icon: 'BarChartIcon',
      children: [
        {
          title: 'breadcrumb.business',
          route: 'list-business',
          icon: '',
          resource: 'business',
          action: 'read'
        },
        {
          title: 'breadcrumb.project',
          route: 'list-project',
          icon: '',
          resource: 'project',
          action: 'read'
        },
      ],
    },
  ];