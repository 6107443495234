export default [
  {
    title: "breadcrumb.article",
    icon: "BookOpenIcon",
    children: [
      {
        title: "breadcrumb.articleCategory",
        route: "list-article-category",
        icon: "",
        resource: "article-category",
        action: "read",
      },
      {
        title: "breadcrumb.article",
        route: "list-article",
        icon: "",
        resource: "article",
        action: "read",
      },
    ],
  },
];
