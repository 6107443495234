import dashboard from "./menu/dashboard";
import websiteHeader from "./menu/websiteHeader";
import careerHeader from "./menu/careerHeader";
import configurationHeader from "./menu/configurationHeader";
import user from "./menu/user";
import timeline from "./menu/timeline";
import contact from "./menu/contact";
import footer from "./menu/footer";
import gallery from "./menu/gallery";
import testimony from "./menu/testimony";
import directory from "./menu/directory";
import articleCategory from "./menu/articleCategory";
import careerGallery from "./menu/careerGallery";
import company from "./menu/company";
import department from "./menu/department";
import source from "./menu/source";
import applicant from "./menu/applicant";
import tenant from "./menu/tenant";
import job from "./menu/job";
import brandCategory from "./menu/brandCategory";
import business from "./menu/business";
import banner from "./menu/banner";
import partner from "./menu/partner";
import award from "./menu/award";
import lifestyle from "./menu/lifestyle";
import pageContent from "./menu/pageContent";
import educationLeisure from "./menu/educationLeisure";

export default [
  ...dashboard,
  ...websiteHeader,
  ...gallery,
  ...brandCategory,
  ...business,
  ...articleCategory,
  ...tenant,
  ...educationLeisure,
  ...timeline,
  ...directory,
  ...partner,
  ...award,
  ...lifestyle,
  ...testimony,
  ...contact,
  ...pageContent,
  ...footer,
  ...careerHeader,
  ...company,
  ...department,
  ...source,
  ...careerGallery,
  ...job,
  ...applicant,
  ...configurationHeader,
  ...user,
];
