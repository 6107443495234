export default [
  {
    title: "breadcrumb.brand",
    icon: "ShoppingBagIcon",
    children: [
      {
        title: "breadcrumb.brandCategory",
        route: "list-brand-category",
        icon: "",
        resource: "brand-category",
        action: "read",
      },
      {
        title: "breadcrumb.brandDirectory",
        route: "list-brand-directory",
        icon: "",
        resource: "brand-directory",
        action: "read",
      },
      {
        title: "breadcrumb.brand",
        route: "list-brand",
        icon: "",
        resource: "brand",
        action: "read",
      },
    ],
  },
];
