export default [
    {
      title: 'breadcrumb.gallery',
      icon: 'ImageIcon',
      children: [
        {
          title: 'breadcrumb.galleryCategory',
          route: 'list-gallery-category',
          icon: '',
          resource: 'gallery-category',
          action: 'read'
        },
        {
          title: 'breadcrumb.gallery',
          route: 'list-gallery',
          icon: '',
          resource: 'gallery',
          action: 'read'
        },
      ],
    },
  ];